import React from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section,  WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';

import SameActivity from '../images/sameActivityMoreData.png'
import RealTimeCoaching from '../images/realTimeCoaching.svg'
import TeamDashboard from '../images/teamDashboard.png'

const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | Powering Better Sales Conversations</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='businessScene2'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            Less Guessing, More Selling
          </PageTitle2>
          <PageTitle color={colors.white} mb="1.5rem" center>
            Better Information Means More Closed Deals
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={SameActivity} />
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
         CALL RECORDING
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem">
            Notes and anecdotes are imperfect; every part of your revenue org is interested/trained in picking up different things.
            <br/><br/>
            Automatic inbound/outbound call recording allows you to bring the unfiltered customer voice to your entire organization.
            <br/><br/>
            This means less handoff meetings, less confusion, less complaining about 'the process' and better customer experiences.

          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperBackground
      color={colors.trulyDark}
      background='headsetPerson1'
    >
      <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
          LESS TIME RESEARCHING, MORE TIME STRATEGIZING
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
            The average SaaS team covers 20% of their pipeline in a week.  The average SaaS win rate is 20%.  Coincidence?
            <br/><br/>
            Our intelligent CRM sync delivers a 2X improvement in call attribution to the correct accounts/contacts without any rep input.
            <br/><br/>
            This allows you to see all activity in one place, and lets you spend less time asking for updates and more time coaching reps on how to progress deals

          </Large>
        </Box>
        <Box width={[1, 1/2]}>
          <CenteredImg src={RealTimeCoaching} alt="Real Time Coaching"/>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperSolid>
      <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
        <Box width="3/4" pb="2rem">
          <WidthImg src={TeamDashboard} />
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          CONTINUOUS TRAINING
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
          Studies show that listening to other reps' calls is one of the fastest and most effective ways to learn, yet it's often a chore building call libraries and keeping them up to date.
          <br/><br/>
          With our Sales Radio feature, teams can passively listen in on LIVE conversations in the background without lifting a finger.  
          <br/><br/>
          This allows everyone from sales reps to product and even the CEO to keep a pulse on the sales floor.
          </Large>

        </Box>
      </Section>
    </WrapperSolid>

  </Default>
)

export default SolutionsPage

const CenteredImg = styled.img`
  display: block;
  margin: 0 auto;
`

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
`
